<script>
  import screen from '@/helpers/screen'

  /**
   * A clickable icon that opens a menu to the left on desktop and half modal on mobile.
   */
  export default {
    props: {
      /** A label for the clickable icon. */
      label: {
        type: String,
        required: true,
      },
      /** The menu will open when this value is true. */
      open: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      screen,
    },
    methods: {
      click() {
        this.$emit('click')
      },
      dismiss() {
        this.$emit('dismiss')
      },
    },
  }
</script>

<template>
  <div>
    <BaseIconClickable :size="4" class="cursor-pointer" :label="label" :padding="0" @click="click">
      <slot name="icon" />
    </BaseIconClickable>

    <!------- Desktop ------->
    <div
      v-if="open && screen.md"
      class="shadow-gray-border bg-white z-20 absolute top-0 right-0 mr-5 py-4 text-xs"
    >
      <slot name="desktop" />
    </div>

    <!------- Mobile ------->
    <BaseHalfModal v-if="!screen.md" :open="open" @dismiss="dismiss">
      <slot name="mobile" />
    </BaseHalfModal>
  </div>
</template>
